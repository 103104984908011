<template>
  <div id="app">
    <div class="main">
      <h3>H5项目目录</h3>
      <div class="container">
        <div class="left">
          <ul>
            <li v-for="item in list"
                @click="src=item.path;iframeTitle=item.name;iphoneSize=item.size"
                :key="item.name">{{item.name}}</li>
          </ul>
        </div>
        <div class="right">
          <div :class="'phone'+iphoneSize">
            <iframe :src="src"
                    frameborder="0"></iframe>
            <div class="iponeTop">
              <div class="inner">

              </div>
              <p class="iframeTitle">{{iframeTitle}}</p>
            </div>
            <div class="iponeBottom"></div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'App',
  data () {
    return {
      list: null,
      src: null,
      iframeTitle: null,
      iphoneSize: null,
    };
  },
  components: {},
  methods: {},
  mounted () {
    axios.get("./list.json").then((response) => {
      this.list = response.data.list;
      this.src = this.list[0].path;
      this.iframeTitle = this.list[0].name;
      this.iphoneSize = this.list[0].size;
      console.log(response);
    });
  },

}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
  .main {
    height: 100vh;
    overflow: hidden;
    h3 {
      text-align: center;
      line-height: 80px;
    }
    .container {
      width: 80vw;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
      .left {
        li {
          cursor: pointer;
        }
      }
      .right {
        height: 858px;
        overflow: hidden;
        .phone750 {
          height: 1716px;
          width: 850px;
          background: url("./assets/iphone.png") #fff center no-repeat;
          background-size: cover;
          position: relative;
          top: 0;
          left: 0;
          transform: scale(0.5) translate(-50%, -50%);
          iframe {
            height: 1432px;
            width: 744px;
            position: absolute;
            left: 56px;
            top: 230px;
            border-bottom-left-radius: 78px;
            border-bottom-right-radius: 78px;
          }
          .iponeTop {
            position: absolute;
            left: 50px;
            top: 50px;
            height: 180px;
            width: 750px;
            border-top-left-radius: 78px;
            border-top-right-radius: 78px;
            .inner {
              height: 60px;
              width: 428px;
              position: absolute;
              left: 164px;
              top: 0;
              background: url("./assets/iphone.png") -214px -50px no-repeat;
            }
            .iframeTitle {
              position: absolute;
              width: 90%;
              left: 0;
              right: 0;
              margin: auto;
              bottom: 0;
              height: 120px;
              line-height: 120px;
              background: #fff;
              text-align: center;
              font-weight: bold;
              font-size: 40px;
              border-top-left-radius: 0px;
              border-top-right-radius: 10px;
            }
          }
          .iponeBottom {
            height: 13px;
            width: 266px;
            position: absolute;
            left: 296px;
            top: 1635px;
            background: url("./assets/iphone.png") -296px -1635px no-repeat;
          }
        }
        .phone640 {
          height: 1456px;
          width: 721px;
          background: url("./assets/iphone640.png") #fff center no-repeat;
          background-size: cover;
          position: relative;
          top: 0;
          left: 0;
          transform: scale(0.5) translate(-50%, -50%);
          iframe {
            height: 1215px;
            width: 631px;
            position: absolute;
            left: 48px;
            top: 195px;
            border-bottom-left-radius: 60px;
            border-bottom-right-radius: 60px;
          }
          .iponeTop {
            position: absolute;
            left: 44px;
            top: 42px;
            height: 150px;
            width: 640px;
            border-top-left-radius: 78px;
            border-top-right-radius: 78px;
            .inner {
              height: 52px;
              width: 364px;
              position: absolute;
              left: 137px;
              top: 0;
              background: url("./assets/iphone640.png") -181px -42px no-repeat;
            }
            .iframeTitle {
              position: absolute;
              width: 90%;
              left: 0;
              right: 0;
              margin: auto;
              bottom: 0;
              height: 100px;
              line-height: 100px;
              background: #fff;
              text-align: center;
              font-weight: bold;
              font-size: 40px;
              border-top-left-radius: 0px;
              border-top-right-radius: 10px;
            }
          }
          .iponeBottom {
            height: 13px;
            width: 266px;
            position: absolute;
            left: 256px;
            top: 1387px;
            background: url("./assets/iphone640.png") -256px -1387px no-repeat;
          }
        }
      }
    }
  }
}
</style>
